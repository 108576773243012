<template>
  <window-content>
    <div class="wrapper-sm">
      <e-row mr>
        <e-col style="min-width: 45%;">
          <date-input label="Data do Lance (De)" v-model="filters.data1"/>
        </e-col>

        <e-col style="min-width: 45%;">
          <date-input label="Data do Lance (Até)" v-model="filters.data2"/>
        </e-col>

        <e-col style="min-width: 98%">
          <comitente-select multiple :columns="[
                      {label: 'Nome', value: 'label'},
                      {label: 'Tipo', value: 'tipoName'}
                    ]" v-model="filters.comitentes" />
        </e-col>

        <e-col style="min-width: 90%">
          <erp-s-field
              view="tl"
              label="ID leilão"
          >
            <erp-input v-model="filters.id" />
          </erp-s-field>
        </e-col>

        <e-col style="min-width: 90%">
          <erp-s-field
              view="tl"
              label="Código leilão"
          >
            <erp-input v-model="filters.codigo" />
          </erp-s-field>
        </e-col>

        <e-col style="min-width: 90%">
          <erp-s-field
              view="tl"
              label="Tipo de lance"
          >
            <div class="flex">
              <label class="flex items-center m-b-xs m-r-xs">
                <u-radio v-model="filters.tipoLance" :val="null" class="m-r-xs"/>
                Todos
              </label>
              <label class="flex items-center m-b-xs m-r-xs">
                <u-radio v-model="filters.tipoLance" val="automatico" class="m-r-xs"/>
                Automático
              </label>
              <label class="flex items-center m-b-xs m-r-xs">
                <u-radio v-model="filters.tipoLance" val="manual" class="m-r-xs"/>
                Manual
              </label>
            </div>
          </erp-s-field>
        </e-col>

        <e-col style="min-width: 90%">
          <erp-s-field
              view="tl"
              label="Forma de pagamento"
          >
            <div class="flex">
              <label class="flex items-center m-b-xs m-r-xs">
                <u-radio v-model="filters.tipoParcelamento" :val="null" class="m-r-xs"/>
                Qualquer
              </label>
              <label class="flex items-center m-b-xs m-r-xs">
                <u-radio v-model="filters.tipoParcelamento" val="parcelado" class="m-r-xs"/>
                Parcelado
              </label>
              <label class="flex items-center m-b-xs m-r-xs">
                <u-radio v-model="filters.tipoParcelamento" val="avista" class="m-r-xs"/>
                À vista
              </label>
            </div>
          </erp-s-field>
        </e-col>
      </e-row>
    </div>
    <window-footer class="text-right">
      <div class="inline-block window-actions">
        <u-btn label="Cancelar" icon="chevron-left" icon-type="fa" icon-style="light" flat no-caps
               @click="$uloc.window.close($root.wid)"/>
        <u-btn @click="gerar" :loading="loading" label="Gerar" icon="file-spreadsheet" icon-type="fa" icon-style="light" flat no-caps/>
      </div>
    </window-footer>
  </window-content>
</template>

<script>
import {
  WindowContent,
  WindowHeader,
  WindowBody,
  WindowFooter,
  ErpSField,
  ErpInput,
  HelperInputBtn,
  ErpBox,
  ErpLabel
  /*ErpSelect*/
} from 'uloc-vue-plugin-erp'
import ERow from "@/components/layout/components/Row"
import ECol from "@/components/layout/components/Col"
import DateInput from "@/reuse/input/Date"
import {date, URadio} from 'uloc-vue'
import mixinPrint from "@/components/relatorios/components/mixinPrint"
import {relatorio} from "@/domain/relatorios/services"
import {datePtToEn} from "@/utils/date"
import ComitenteSelect from "@/components/comitente/helpers/input/ComitenteSelect"

let filters = {
  id: null,
  codigo: null,
  data1: null,
  data2: null,
  comitentes: [],
  tipoLance: null,
  tipoParcelamento: null
}

export default {
  name: 'RelatorioLancesWindow',
  mixins: [mixinPrint],
  props: {
  },
  data() {
    let f = JSON.parse(JSON.stringify(filters))
    return {
      filters: f
    }
  },
  mounted() {
  },
  computed: {},
  methods: {
    gerar() {
      let data1, data2, dataS1, dataS2
      let filters = JSON.parse(JSON.stringify(this.filters))
      this.loading = true
      if (filters.data1) {
        data1 = datePtToEn(filters.data1)
        data2 = datePtToEn(filters.data2)
        if (!date.isValid(data1) || !date.isValid(data2)) {
          alert('Digite a data inicial e data final corretamente. Formato: dd/mm/yyyy')
          return
        }
        filters.data1 = data1
        filters.data2 = data2
      }

      if (Array.isArray(this.filters.comitentes) && this.filters.comitentes.length > 0) {
        filters.comitentes = this.filters.comitentes.join(',')
      }

      relatorio('lances', filters)
          .then(response => {
            this.loading = false
          })
          .catch(error => {
            this.loading = false
            error.data.text().then(text => {
              this.alertApiError({data: JSON.parse(text)})
            })
            console.log(error)
          })
    }
  },
  components: {
    ComitenteSelect,
    DateInput,
    ECol,
    ERow,
    WindowFooter,
    // WindowBody,
    // WindowHeader,
    WindowContent,
    ErpSField,
    ErpInput,
    // HelperInputBtn,
    // ErpBox,
    // ErpLabel
    // UEditor,
    URadio
    // ErpSelect
  }
}
</script>
